<template>
	<div>
		
		<b-sidebar
		id="add-new-user-sidebar"
		:visible="isAddNewUserSidebarActive"
		bg-variant="white"
		sidebar-class="sidebar-lg"
		shadow
		backdrop
		no-header
		right
	  @hidden="resetForm"
	  @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
	  
	  
	  />

	  <div class ="main-title">

		  <h2><center>Create Orders</center></h2>
		  <br/><br/>
	  </div>
	  
	  <div class="c_order">
		<b-button @click="fetchData" variant="primary">Create order</b-button>
		<i class="fa-solid fa-arrows-rotate refresh_icon" @click="showTable"></i>

	  </div>

<!-- 	  
	<b-modal modal-footer  size="xl" v-model="showModal">
		<div style="width: 133%;">

			<OrderPreview/>
		</div>
		<template #modal-footer>
        <div class="w-100">
          
        </div>
      </template>
		
	</b-modal> -->
	<b-modal v-model="noItemsModal">
<span>
	Order created successfully
</span>
	</b-modal>

	<VendorOrder v-if="showVendorTable" class="vendor-order"/>
	  
  
	</div>
  </template>
  
  <script>
  import {
	BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { ref } from '@vue/composition-api'
  import { required, alphaNum, email } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import OrderPreview from './OrderPreview.vue'
  import countries from '@/@fake-db/data/other/countries'
  import store from '@/store'
  import { mapMutations, mapActions, mapState } from "vuex";
  import VendorOrder from '../vendorOrder/vendorOrder.vue'
  
  export default {
	components: {
	  BSidebar,
	  BForm,
	  BFormGroup,
	  BFormInput,
	  OrderPreview,
	  BFormInvalidFeedback,
	  BButton,
	  vSelect,
  
	  // Form Validation
	  ValidationProvider,
	  ValidationObserver,
	  VendorOrder
	},
	model: {
	  prop: 'isAddNewUserSidebarActive',
	  event: 'update:is-add-new-user-sidebar-active',
	},
	props: {
	  isAddNewUserSidebarActive: {
		type: Boolean,
		required: true,
	  }
  
	},
	data(){
	  return {
		name:"",
		showModal: false,
		noItemsModal: false,
		showVendorTable: false
	  }
	},
	directives: {
	  Ripple
	},
	setup(props, { emit }) {
  
	  let name = ref('');
	  const {
		refFormObserver,
		getValidationState,
		resetForm,
	  } = formValidation(name)
  
	  return {
		name,
		refFormObserver,
		getValidationState,
		resetForm,
	  }
	},
	computed:{
	  ...mapState({
	   getData: (state) =>{
		 return state.adminCreateOrder.data;
	   },
	  })
	},
	methods:{
	  ...mapActions({
		fetchDatas:"adminCreateOrder/fetchData"
	  }),
	  async fetchData(){
		await this.fetchDatas()
		if (this.getData.length>=1){

			this.showModal = true
		}else{
			this.noItemsModal = true
		}
	  },
	  showTable(){
		this.showVendorTable = true
	  }
	}
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  
  #add-new-user-sidebar {
	.vs__dropdown-menu {
	  max-height: 200px !important;
	}
  }
  .c_order {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-right: 10rem;
  }
  
  </style>

  <style scoped>
  .refresh_icon{
	width: 3rem;
	height: 3rem;
  }
  .vendor-order{
	margin-top: 1.5rem;
	margin-right: 2rem;
  }
  </style>
  