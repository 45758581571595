   <template>
    <div>
      <b-table
        sticky-header="400px"
        :hover="true"
        responsive
        :items="vendors"
        :fields="fields1"
      >

      <template v-for="field in vendors"
                 v-slot:cell(serialNo)="field">
          <div>{{field.index+1}}</div> 
      </template>

      <template v-for="field in vendors"
                 v-slot:cell(name)="field">
          <div>{{field.item.vendor[0].name}}</div> 
      </template> 
      <template v-for="field in vendors"
                 v-slot:cell(OrderedDate)="field">
          <div>{{field.item.OrderedDate.substring(4, 15)}}</div> 
      </template>  
      <template v-for="field in vendors"
                 v-slot:cell(OrderStatus)="field">
                 <div class="orderStatus">
                   <div>{{field.item.OrderStatus}}</div> 
                   <i v-if="field.item.OrderStatus!='Delivered'" class="fa-solid fa-circle-info" style="margin-left: 1rem; margin-top: 0.3rem" @click="editOrderStatus(field.item)"></i>
                 </div>
      </template> 
      <template v-for="field in vendors"
                 v-slot:cell(functions)="field">
          <i class="fa-solid fa-eye" @click="onClickShowOrderDetails(field.item)"></i>
          <i class="fa-solid fa-print" style="margin-left: 1rem;" @click="printOrderDetails(field.item, field.item.OrderStatus)"></i>
          <!-- <i class="fa-solid fa-file-invoice" style="margin-left: 1rem;" @click="printLabel(field.item)"></i> -->
      </template>  
    </b-table>

    <div class="lower_text">For printing label please use ops mobile app.</div>

    <ViewOrder :open="showOrderDetails" :value="orderDetails" :vendorName="vendorName" :orderId="orderId" :orderDate="orderDate" ref="vieworder"/>
    <PrintOrder :value="pOrderDetails" :status="orderStatus" ref="printorder"/>
    <b-modal
        id="modal-center"
        centered
        v-model="modalShow"
        size="lg"
        ref="editStatus"
        title=""
        @ok="changeStatus"
        @cancel="cancel"
    >
    <div class="order_text">
      Do you want to change order status to Delivered?
    </div>
    </b-modal>
    </div>
   </template>
   
   <script>
   import Ripple from "vue-ripple-directive";
   import { ValidationProvider, ValidationObserver } from "vee-validate";
   import { mapActions, mapState, mapMutations } from "vuex";
   import { BNav, BNavItem, BNavbarNav } from "bootstrap-vue";
   import {
     BTable,
     BFormCheckbox,
     BAvatar,
     BBadge,
     BPagination,
     BModal
   } from "bootstrap-vue";
   
   import {
     BRow,
     BCol,
     BLink,
     BButton,
   } from "bootstrap-vue";
   import ViewOrder from './viewOrder.vue'
   import PrintOrder from '../createOrder/OrderPreview.vue'
   import Preview from '../../../views/apps/invoice/invoice-preview/InvoicePreview.vue'

   export default {
     components: {
       BRow,
       BCol,
       BLink,
       BTable,
       BAvatar,
       BBadge,
       BButton,
       BFormCheckbox,
       ValidationProvider,
       ValidationObserver,
       BNav,
       BNavItem,
       BNavbarNav,
       BPagination,
       ViewOrder,
       PrintOrder,
       Preview
     },
     data() {
      return {
        fields1: [
          { label: "SNo.", key: "serialNo" },
          { label: "Order Id", key: "OrderNo" },
          { label: "Order Date", key: "OrderedDate" },
          { label: "Vendor Name", key: "name" },
          { label: "Order Status", key: "OrderStatus" },
          // { label: "View Order Details", key: "orderDetails" },
          // { label: "Print Order", key: "printOrder" },
          { label: "Functions", key: "functions" },
        ],
        showOrderDetails: false,
        orderDetails: '',
        vendorName: '',
        orderId: '',
        orderDate: '',
        printOrder: false,
        pOrderDetails: '',
        modalShow: false,
        details: '',
        orderStatus: '',
        count: 0
      }
     },
     props: {

     },
     directives: {
       Ripple,
     },
     computed: mapState({
       vendorOrderItems: (state) => {
         return state.vendorOrder.vendorOrderItems;
       },
       vendors: state => {
        return state.vendorOrder.vendorOrderItems
       }
     }),
     mounted() {
      this.getVendorOrder();
     },
   
     methods: {
       ...mapMutations({
         
       }),
       ...mapActions({
          getVendorOrder: 'vendorOrder/getVendorOrderItems',
          editStatus: 'vendorOrder/editOrderStatus',
          printOrderLabel: 'vendorOrder/printLabel'
       }),
       onClickShowOrderDetails(val){
        this.$refs.vieworder.showModal();
        this.orderDetails = val.items
        this.vendorName = val.vendor[0].name
        this.orderId = val.OrderNo
        this.orderDate = val.OrderedDate
       },
       close() {
        this.showOrderDetails = !this.showOrderDetails
       },
       printOrderDetails(val, status) {
        this.pOrderDetails = val
        this.orderStatus = status
        this.$refs.printorder.showModal()
       },
       editOrderStatus(val) {
        this.modalShow = true
        this.details = val
       },
       changeStatus(){
        this.editStatus({id: this.details.id})
        window.location.reload()
       },
       printLabel(val) {
        this.printOrderLabel({value: val})
       }
    }
   };
   </script>
   
   <style scoped>
   .orderStatus {
    display: flex;
    flex-direction: row;
   }
   .order_text {
    font-size: 1.5rem;
   }
   .lower_text {
    font-size: 1.2rem;
    color: black;
    margin-top: 2rem;
   }
   </style>