<template>
    <div class="item-modal">
      <b-modal
        id="modal-center"
        centered
        v-model="modalShow"
        :title="title"
        size="lg"
        hide-footer
        ref="vieworder"
      >
        <b-table
          sticky-header="400px"
          :bordered="true"
          :hover="true"
          responsive
          :items="value"
          :fields="fields"
        >

        <template v-for="field in value"
                 v-slot:cell(orderId)="field">
            <div>{{orderId}}</div>
        </template> 

        <template v-for="field in value"
                 v-slot:cell(OrderedDate)="field">
            <div>{{orderDate.substring(4, 15)}}</div>
        </template> 

        <template v-for="field in value"
                 v-slot:cell(pName)="field">
            <div>{{field.item.products[0].name}}</div>
        </template> 
        <template v-for="field in value"
                 v-slot:cell(vName)="field">
            <div>{{field.item.variants[0].name}}</div>
        </template> 
        </b-table>
      </b-modal>
    </div>
  </template>
  
  <script>
  import { BModal, VBModal, BAlert, BCardText, BTable } from "bootstrap-vue";
  import BCardCode from "@core/components/b-card-code";
  import Ripple from "vue-ripple-directive";
  
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { mapMutations, mapActions, mapState } from "vuex";
  import {
    BRow,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BCardTitle,
  } from "bootstrap-vue";
  
  export default {
    props: ["open", "value", "vendorName", "orderId", "orderDate"],
    components: {
      BTable,
      BCardCode,
      BButton,
      BModal,
      BAlert,
      BRow,
      BImg,
      BCol,
      BLink,
      BForm,
      BCardText,
      BCardTitle,
      BFormCheckbox,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BInputGroup,
      BInputGroupAppend,
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
      "b-modal": VBModal,
    },
  
    data() {
      return {
        modalShow: this.open,
        fields: [
          { label: "Order Id", key: "orderId" },
          { label: "Order Date", key: "OrderedDate" },
          { label: "Product Name", key: "pName" },
          { label: "Variant Name", key: "vName" },
          { label: "Quantity", key: "quantity" },
        ],
        title: ''
      };
    },
    computed: mapState({
    }),
    methods: {
        showModal() {
            this.$refs.vieworder.show()
            this.title = `Vendor Name:- ${this.vendorName}`
        },
        close() {
          this.$emit("close");
        },
    },
    mounted() {
        this.modalShow = this.open
        this.title = `Vendor Name:- ${this.vendorName}`
    },
  };
  </script>
  <style scoped>
  .image {
    width: 55px;
    height: 53px;
    object-fit: contain;
  }
  </style>