<template>
	<b-modal  class="invoice-preview-wrapper"
	    id="modal-center"
        centered
        v-model="modalShow"
		title="Order Preview"
        size="lg"
        hide-footer
        ref="orderpreview">
  
	  <!-- Alert: No item found -->
	 
	  <b-row
		
		class="invoice-preview"
	  >
  
		<!-- Col: Left (Invoice Container) -->
		<b-col
		  cols="12"
		>
		 
		  <b-card
		  id='printArea'
			no-body
			class="invoice-preview-card"
		  >
			<!-- Header -->
			<div class="order_details">
				<div>Order Id. - {{value.OrderNo}}</div>
				<div>Order Date - {{value.OrderedDate.substring(4, 15)}}</div>
			 </div>
			<b-card-body class="invoice-padding pb-0 upper__h">
				
  
			  <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
  
				<!-- Header: Left Content -->
				<div>
				   From:-

				  <div class="logo-wrapper" style="margin-bottom: -0.1rem; margin-top: 0.5rem">
					<logo style="margin-bottom: 0.5rem;"/>
					
				  </div>
				  <!-- <p class="card-text mb-25">
					Your Indian Cart, Bangalore
				  </p> -->
				  <p class="card-text mb-25">
					info@yourindiancart.com
				  </p>
				  <p class="card-text mb-0">
					+1 01 234 567 88
				  </p>
				</div>
  
				<!-- Header: Right Content -->
				
			  </div>

			  <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
  
				<!-- Header: Left Content -->
				<div>
				  To:-
				  <p class="card-text mb-25" style="margin-top: 0.5rem;">
					Vendor Id:- {{value.vendor[0].id}}
				  </p>
				  <p class="card-text mb-25">
					Vendor Name:- {{value.vendor[0].name}}
				  </p>
				</div>
  
				<!-- Header: Right Content -->
				
			  </div>
			</b-card-body>
  
			<!-- Spacer -->
			<hr class="invoice-spacing">
  
			<!-- Invoice Client & Payment Details -->
			<b-card-body
			  
			  class="invoice-padding pt-0"
			>
			  
  
			<!-- Invoice Description: Table -->
			<b-table-lite
			  sticky-header="400px"
              :hover="true"
			  responsive
			  :items="value.items"
			  :fields="['Item', 'Quantity', 'Status']"
			>
			<template v-for="field in value"
			        v-slot:cell(item)="field">
	            <div>{{field.item.products[0].name}}</div> 
            </template>
			<template v-for="field in value"
			        v-slot:cell(quantity)="field">
	            <div>{{field.item.quantity}}</div> 
            </template>  
			<template v-for="field in value"
			        v-slot:cell(status)="field">
	            <div>{{status}}</div> 
            </template>  
			</b-table-lite>
		</b-card-body>

  
			<!-- Invoice Description: Total -->
			
			<!-- Spacer -->
			<hr class="invoice-spacing">
  
			<!-- Note -->
			
		  </b-card>
		  <b-card>
  <!-- Button: Send Invoice -->
  <!-- Button: DOwnload -->
  <b-button
	v-ripple.400="'rgba(186, 191, 199, 0.15)'"
	variant="outline-secondary"
	class="mb-75"
	@click="print"
	block
  >
	Print
  </b-button>
  <!-- Button: Print -->
</b-card>
		</b-col>
		<!-- Right Col: Card -->
	  </b-row>
	</b-modal>
  </template>
  <script>
  import { ref, onUnmounted } from '@vue/composition-api'
  import store from '@/store'
  import router from '@/router'
  import { mapMutations, mapActions, mapState } from "vuex";

  import {
	BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BModal, BTable
  } from 'bootstrap-vue'
  import Logo from '@core/layouts/components/Logo.vue'
  import Vue from 'vue';
  import Ripple from 'vue-ripple-directive'
  import VueHtmlToPaper from 'vue-html-to-paper';
  const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
}
Vue.use(VueHtmlToPaper, options);

  
  export default {
	props: ["value", "status"],
	directives: {
	  Ripple,
	  'b-toggle': VBToggle,
	},
	components: {
	  BRow,
	  BCol,
	  BCard,
	  BCardBody,
	  BTableLite,
	  BCardText,
	  BButton,
	  BAlert,
	  BLink,
	  Logo,
	  
	},
	data (){
		return{
			fields: [
              { label: "Item", key: "item" },
              { label: "Quantity", key: "quantity" },
              { label: "Status", key: "status" },
            ],
			output: null,
			val: '',
			items: ''
		}
	},
	computed:{
	  ...mapState({
		
	//    items: (state) =>{
	// 	 return state.adminCreateOrder.items;
	//    },
	   paymentDetails: (state) =>{
		 return state.adminCreateOrder.paymentDetails;
	   },
	  })
	},
	methods: {
		print () {
      // Pass the element id here
        this.$htmlToPaper('printArea');
       },
	    showModal() {
            this.$refs.orderpreview.show();
        },
	},
	setup() {
	  
	  // Invoice Description
	  // ? Your real data will contain this information
	  
  
	
	  // Register module
	  
  
	 
	  const printInvoice = () => {
		this.$htmlToPaper('printArea');
		// window.print()
	// 	var printContents = document.getElementById('printArea').innerHTML;
	// 		var originalContents = document.body.innerHTML;

	// 		document.body.innerHTML = printContents;

	// 		window.print();

	// 		document.body.innerHTML = originalContents;
	  }
  
	  return {
		
		printInvoice,
	  }
	},
  }
  </script>
  
  <style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";
  .upper__h {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
  }
  .order_details{
	margin: 2rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
  }
  </style>
  
  <style lang="scss">
  @media print {
  
	// Global Styles
	body {
	  background-color: transparent !important;
	}
	nav.header-navbar {
	  display: none;
	}
	.main-menu {
	  display: none;
	}
	.header-navbar-shadow {
	  display: none !important;
	}
	.content.app-content {
	  margin-left: 0;
	  padding-top: 2rem !important;
	}
	footer.footer {
	  display: none;
	}
	.card {
	  background-color: transparent;
	  box-shadow: none;
	}
	.customizer-toggle {
	  display: none !important;
	}
  
	// Invoice Specific Styles
	.invoice-preview-wrapper {
	  .row.invoice-preview {
		.col-md-8 {
		  max-width: 100%;
		  flex-grow: 1;
		}
  
		.invoice-preview-card {
		  .card-body:nth-of-type(2) {
			.row {
				> .col-12 {
				max-width: 50% !important;
			  }
  
			  .col-12:nth-child(2) {
				display: flex;
				align-items: flex-start;
				justify-content: flex-end;
				margin-top: 0 !important;
			  }
			}
		  }
		}
	  }
  
	  // Action Right Col
	  .invoice-actions {
		display: none;
	  }
	  
	}
  }
  </style>
  